/* This plugin detects if you click outside an element */
(function(jQuery) {
   jQuery.fn.clickoutside = function(callback) {
      var outside = 1, self = jQuery(this);
      self.cb = callback;
      this.click(function() {
         outside = 0;
      });
      jQuery(document).click(function(e) {
        if(e.which != 3 && e.clientX != 0) {
           outside && self.cb(e);
           outside = 1;
        }
      });
      return jQuery(this);
   }
})(jQuery);