/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './css/app.scss';

// start the Stimulus application
//import './bootstrap';


/* JS  */
require("./js/lib/bootstrap/bootstrap.js");
require("./js/lib/adminlte.min.js");
require("./js/lib/jQuery.resizableColumns.js");
require("./js/lib/slick.js");
require("./js/lib/jquery.mCustomScrollbar.js");
require("./js/clickoutside.js");
require("./js/main.front.js");
require("./js/main.back.js");
